.titleBarContainer {
  position: relative;
    display: flex;
    flex-direction: column;
    background: #023246EE;
    color: #ffffff;
    min-height: 100px;
    max-width: 1000px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    border-radius: 6px;
}

.titleBarName {
    margin: 10px 15px;
    font-weight: bold;
    font-size: 16px;
}

.titlePosititon {
    margin: 5px 20px;
    font-size: 14px;
}

.horizontalBreak {
    width: 96%;
    margin: 10px auto;
    text-align: center;
}

.titleAddress, .titleContacts {
    margin: 2px 20px;
}

.titleContacts {
    margin-bottom: 10px;
}

.titlePhoto {
  position: absolute;
  align-self: flex-end;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-top: 20px;
  margin-left: -15px;
}

.titlePhoto:hover ~ .titlePhotoHoverText {
    animation: none;
    visibility: hidden;
}

.titlePhoto:hover ~ .titlePhotoPopup {
    visibility: visible;
}

.titlePhotoHoverText {
    position: absolute;
    color: white;
    transform: rotate(-45deg);
    right: 70px;
    top: 20px;
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    font-weight: bold;
    animation: flash infinite 2s ease-in-out;
    user-select: none;
}

.titlePhotoPopup {
    position: absolute;
    min-width: 190px;
    right: 0;
    bottom: 5px;
    height: 50px;
    visibility: hidden;
}

.titlePhotoPopup:hover {
    visibility: visible;
}

.titlePhotoPopup:hover ~ .titlePhotoHoverText {
    animation: none;
    visibility: hidden;
}

.titleIcon {
    max-width: 30px;
    max-height: 30px;
    margin: 10px;
    cursor: pointer;
}

@keyframes flash {
    from {
        color: white;
    }
    to {
        color: transparent;
    }
  }