.sliderContainer {
  max-width: 1000px;
  margin: auto;
  font-family: Helvetica;
  font-weight: 400;
  background: #f6f6f6EE;
  border-radius: 6px;
}

.sliderTitle {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  color: #525f7f;
}

.carrousel {
  text-align: center;
  padding: 1em 0 4em 0;
  max-width: 1000px;
  margin: auto;
  position: relative;
  overflow: hidden;

}
.carrousel h2 {
  margin: 0;
  margin-top: -1.7em;
  padding: 0;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  color: #023246;
}
.carrousel .slides {
  width: 500%;
  left: 0;
  padding-left: 0;
  padding-top: 1em;
  overflow: hidden;
  list-style: none;
  position: relative;

  -webkit-transition: transform .5s;
  -moz-transition: transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
}
.carrousel .slides li {
  width: 20%;
  position: relative;
  float: left;
}
.carrousel li p{
  margin-top: 0;
}
.carrousel li q {
  max-width: 90%;
  margin: auto;
  color: #666666;
  font-size: 1.3em;
  font-weight: bold;
}
.carrousel li img {
  width: 3em;
  height: 3em;
  object-fit: scale-down;
  border-radius: 50%;
  /* margin-left: -1.5em; */
  /* margin-right: 0.5em; */
  vertical-align: middle;
}
.carrousel li span.author {
  margin-top:0.5em;
  font-size: 1.2em;
  color: #d4d4ce;
  display: block;
}
.carrousel .slidesNavigation {
  display: block;
  list-style: none;
  text-align: center;
  bottom: 1em;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.carrousel input {
  display: none;
}
.carrousel .slidesNavigation label {
  float: left;
  margin: 6px;
  display: block;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: solid 2px #287094;
  font-size: 0;
}
.slidesNavigation {

}
/* You have to repeat this with each slide
TODO: make it easier with SCSS
25% movement 100/slides-num
*/
#radio-1:checked ~ .slides {
  transform: translateX(0%);
}
#radio-2:checked ~ .slides {
  transform: translateX(-20%);
}
#radio-3:checked ~ .slides {
  transform: translateX(-40%);
}
#radio-4:checked ~ .slides {
  transform: translateX(-60%);
}
#radio-5:checked ~ .slides {
  transform: translateX(-80%);
}


.carrousel .slidesNavigation label:hover {
   cursor: pointer;
}
/* You have to repeat this with each slide/dot */
.carrousel #radio-1:checked ~ .slidesNavigation label#dotForRadio-1,
.carrousel #radio-2:checked ~ .slidesNavigation label#dotForRadio-2,
.carrousel #radio-3:checked ~ .slidesNavigation label#dotForRadio-3,
.carrousel #radio-4:checked ~ .slidesNavigation label#dotForRadio-4,
.carrousel #radio-5:checked ~ .slidesNavigation label#dotForRadio-5 {
  background: #287094;
}

@media  (max-width: 796px) {
  .carrousel{
    height: 8.5em;
  }
}
@media  (max-width: 480px) {
  .carrousel li p {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .carrousel li q {
      font-size: 1em;
  }
  .carrousel li img {
     width:2em;
     /* margin-left: -1em; */
     /* margin-right: 0.25em; */
  }
}
