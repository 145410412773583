.workHistoryContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-size: 14px;
    background: #f6f6f6EE;
    font-family: "Open Sans", sans-serif;
    color: #525f7f;
    margin: 10px auto;
    max-width: 1000px;
    border-radius: 6px;
}

.workHistoryTitle {
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: #525f7f;
}

.workHistory {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    position: relative;
}

.workHistoryItem {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    margin: 20px 0;
    border-radius: 6px;
    align-self: center;
    width: 95vw;
    max-width: 1000px;
}

.workHistoryItem:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    transition-duration: 0.3s;

}

.workHistoryItem:nth-child(2n + 1) {
    flex-direction: row-reverse;
}
.workHistoryItem:nth-child(2n + 1) .workHistoryItemDate {
    border-radius: 0 6px 6px 0;
}
.workHistoryItem:nth-child(2n + 1) .workHistoryItemContent {
    border-radius: 6px 0 0 6px;
}

.workHistoryItemTitle {
    font-size: 1rem;
    line-height: 1.4;
    text-transform: uppercase;
    font-weight: 600;
    color: #5B5B5B;
    letter-spacing: 1.5px;
}
.workHistoryItemContent {
    padding: 20px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    background: #fff;
    width: calc(55vw - 84px);
    border-radius: 0 6px 6px 0;
}
.workHistoryItemDate {
    color: #B4B4B4;
    font-size: 1em;
    font-weight: 600;
    background: #023246;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 0 10px;
    border-radius: 6px 0 0 6px;
}
.workHistoryItemDescription {
    flex-basis: 60%;
}
.workHistoryItemIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5B5B5B;
    padding: 5px;
    align-self: center;
    margin: 0 20px;
    background: #287094;
    border-radius: 100%;
    width: 40px;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    height: 40px;
    position: relative;
}
.workHistoryItemIcon i {
    font-size: 1em;
}

.workHistoryItem:nth-child(4n+1) .workHistoryItemDate, .workHistoryItem:nth-child(4n+1) .workHistoryItemIcon {
    background-color: #FA991C;
    color: #023246;
}
.workHistoryItem:nth-child(4n+2) .workHistoryItemDate, .workHistoryItem:nth-child(4n+2) .workHistoryItemIcon {
    background-color: #1C768F;
    color: #f6f6f6;
}
.workHistoryItem:nth-child(4n+3) .workHistoryItemDate, .workHistoryItem:nth-child(4n+3) .workHistoryItemIcon {
    background-color: #032539;
    color: #f6f6f6;
}
.workHistoryItem:nth-child(4n+4) .workHistoryItemDate, .workHistoryItem:nth-child(4n+4) .workHistoryItemIcon {
    background-color: #FA5F1A;
    color: #023246;
}